.image-viewer-column {
  width: 600px;
  overflow: hidden;
  background-color: black;
  font-size: 0;  
}

.image-container {
  position: relative;
  width: 600px;
}

.sea-dragon-viewer-overlay {
  width: 100%;
  height: 100%;
  //position: absolute;
  top: 0;
  left: 0;
}

.image-column-viewer-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.test-image {
  display: inline-block;
  color: white;
  background-image: url("../../images/loading-scaled-image.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 2px;
}

.cuttings-image {  
  color: white;
  background-image: url("../../images/loading-scaled-image.png");
  background-repeat: no-repeat;
  background-size: cover;  
}