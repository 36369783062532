.lithology-edit-header {
    position: sticky;
    top: -4px;
    z-index: 5;
    background-color: white;    
}

.lithology-select-box {
    width: 13px;
    height: 13px;
    display: inline-block;
    border: 1px solid black;
    margin-right: 0.3rem;
    position: relative;
    top: 1px
}

.lithology-lithology {
    padding-left: 2px;
    padding-right: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;    
}

.lithology-code {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;    
}

.lithology-track-header {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 4px;
    padding-right: 4px;
}