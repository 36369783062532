img.borehole {
  // max-height: 2000px;
  // max-height: 650px;
  // min-height: 650px;
  display: block;
  padding: 0;
  margin: 0;
  overflow-y: hidden;
  border: black 1px solid;
  /* IE, only works on <img> tags */
  -ms-interpolation-mode: nearest-neighbor;
  /* Firefox */
  image-rendering: crisp-edges;
  /* Chromium + Safari */
  image-rendering: pixelated;
}

.image-column {
  font-size: 0;
  flex-grow: 0 !important;
}

.flex-container {
  display: flex;
  flex-wrap: nowrap;
  margin: 0 !important;
  //overflow: auto;
  //width: 100%;
  //height: 100%;
}

.flex-item {
  flex: 0 0 auto;
  padding: 0 !important;
}

.track-container {
  display: flex;
  align-items: flex-start;
  position: absolute;
}

.group-header-container {
  min-width: 100px;
  display: flex;
  
  .group-viewer-header {
    
    justify-content: center;  
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: auto;
    margin-bottom: auto;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 29px;
    padding-right: 4px;
    width: 100%;
    text-align: center;
    
  }
  
  .group-arrow-container {
    justify-content: right;
    align-items: center;
    align-self: center;           
  }

  .group-arrow {
    justify-content: right;
    align-items: center;
    align-self: center;   
   
  }

  .group-header-arrow {
    justify-content: right;
    align-items: center;
    align-self: center;   
    position: relative;
    top: -3px; 
    width: 10px;
    height: 10px;
  }

  .group-header-arrow-container {
    float: right;
  }

  .group-header-button {
    height: 25px !important;
    width: 25px !important;
    align-items: center;
    justify-items: center;
    margin-top: 5px;
    margin-right: 5px;
    margin-bottom: 5px;    
    padding: 0 !important;    
  }
}

.group-outline {
  border: 1px solid #AAAAAA;
  margin-right: 5px;  
  transition: max-width 1s;  
  transition-timing-function: ease;
}
